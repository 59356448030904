<template>
  <div class="container px-0 h-100">
    <SettingsView />
  </div>
</template>

<script>
import SettingsView from "../../content/settings/View";

export default {
  name: "SettingsPageView",
  components: { SettingsView }
};
</script>
